import APIService from '@/services/api-service'
const resource = '/summary'

class SearchService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public searchJobs (data) {
    return this.connector.post(`${resource}/search-jobs`, data)
  }

  public getJobsSummary (data) {
    return this.connector.post(`${resource}/jobs`, data)
  }

  public getJobSummary (searchKey) {
    return this.connector.post(`${resource}/job`, { searchKey: searchKey })
  }

  public getJobsReport (serviceId) {
    return this.connector.get(`${resource}/${serviceId}/jobs-report`)
  }

  public searchJobsByServiceId (serviceId) {
    return this.connector.get(`${resource}/${serviceId}/search-jobs`)
  }

  public searchBargeOperations (data) {
    return this.connector.post(`${resource}/search-barge-operations`, data)
  }
}

export default new SearchService()
