import APIService from '@/services/api-service'
const resource = '/job'
const subResource = 'mode'

class CollectionService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public save (job:any, modes: any) {
    return this.connector.post(`${resource}/${subResource}/save`, { job: job, modes: modes })
  }

  public getBargeNames () {
    return this.connector.get('/barge/names')
  }

  public getAllocatedBarge (jobId: number) {
    return this.connector.get(`${resource}/${subResource}/${jobId}`)
  }

  public checkActivitiesExist (jobId: any, bargeId: any) {
    return this.connector.get(`${resource}/${subResource}/${jobId}/${bargeId}/check-activities`)
  }

  public getNoOfTrips (jobId: any, bargeId: any) {
    return this.connector.get(`${resource}/${subResource}/${jobId}/${bargeId}/no-of-trips`)
  }

  public deleteBarge (jobId: any, bargeId: any) {
    return this.connector.delete(`${resource}/${subResource}/${jobId}/${bargeId}`)
  }

  public scheduleOperation (job: any) {
    return this.connector.post(`${resource}/${subResource}/create/schedule-operation`, job)
  }
}

export default new CollectionService()
