
import {
  Component,
  Vue
} from 'vue-property-decorator'
import DateHelper from '@/mixins/date-mixins'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
  @Component({
    name: 'SearchSummary'
  })

export default class SearchSummary extends Vue {
  get date () {
    return DateHelper.today('dd/mm/yyyy')
  }

  created (): void {
    this.boot()
  }

  protected boot () {
    BreadcrumbModule.setBreadcrumb(this.$route)
  }
}

