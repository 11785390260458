
import {
  Component,
  Vue
} from 'vue-property-decorator'
import DateHelper from '@/mixins/date-mixins'
import RepositoryService from '@/views/job/services/repository-service'
import BoardService from '@/views/job/services/board-service'
import SearchService from '../services/search-service'
import CollectionService from '@/views/job/services/collection-service'
import Status from '@/components/Status/index.vue'
import {
  utils,
  writeFile
} from 'xlsx'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
  @Component({
    name: 'Summary',
    components: {
      Status
    }
  })

export default class Summary extends Vue {
    protected showComponent = false
    protected isLoader = false
    protected isResult = false
    protected isBusy = false
    protected isSearchByDate = false
    protected isBargeOperation = false
    protected jobs: any = []
    protected date = {
      from_date: null,
      to_date: null,
      service: {}
    }

    protected bargeOperation = {
      from_date: null,
      to_date: null
    }

    protected jobDetails: any = []
    protected searchKey: any = null
    protected services: any = []
    protected barges: any = []
    protected selectedServices: any = []
    protected selectedService: any = {}
    protected activities: any = {}
    // protected jobSummary: any = {}

    protected fields = [{
      key: 'created_date',
      label: 'Date',
      sortable: true,
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'job_number',
      label: 'Job No.',
      sortable: true,
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'reference_number',
      label: 'Ref No.',
      sortable: true,
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'vessel_name',
      label: 'Vessel',
      sortable: true,
      thStyle: {
        width: '12%'
      }
    },
    {
      key: 'port_name',
      label: 'Port/Terminal',
      sortable: true,
      thStyle: {
        width: '6%'
      }
    },
    {
      key: 'lines',
      label: 'Services',
      thStyle: {
        width: '12%'
      },
      sortable: true
    },
    {
      key: 'status',
      label: 'Status',
      class: 'text-center',
      sortable: true,
      thStyle: {
        width: '8%'
      }
    }
    ]

    protected bargeOperationfields = [{
      key: 'date',
      label: 'Date',
      sortable: true,
      thStyle: {
        width: '5%'
      }
    },
    {
      key: 'job_number',
      label: 'Job No.',
      sortable: true,
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'type_of_cargo',
      label: 'Type of Cargo',
      sortable: true,
      thStyle: {
        width: '6%'
      }
    },
    {
      key: 'quantity_collected',
      label: 'Quantity Collected',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'quantity_delivered',
      label: 'Quantity Delievered',
      thStyle: {
        width: '8%'
      }
    }
    ]

    /* created (): void {
      this.boot()
    } */

    get rows () {
      if (this.isBargeOperation) return this.activities.length
      else if (!this.isBargeOperation) return this.jobs.length
    }

    public show () {
      this.showComponent = true

      this.populateServices()
      this.populateBarges()
    }

    protected hide () {
      this.cancel()
      this.cancelSelectedService()
      this.cancelBargeOperation()
      this.showComponent = false
    }

    /* protected boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      // this.populateJobs()
      this.populateServices()
      this.populateBarges()
    } */

    protected async populateJobs () {
      const response = await BoardService.getOfficeJobs()
      this.jobs = response.data
    }

    protected search () {
      this.jobs = []
      this.isResult = false
      this.isLoader = true
      this.isBargeOperation = false
      this.isSearchByDate = false
      SearchService.searchJobs(this.date).then((response) => {
        this.isLoader = false
        this.isResult = true
        this.isSearchByDate = true
        this.jobs = response.data
      })
    }

    protected searchJobsByServiceId () {
      this.jobs = []
      this.isResult = false
      this.isLoader = true
      this.isBargeOperation = false
      this.isSearchByDate = false
      SearchService.searchJobsByServiceId(this.selectedService.id).then((response) => {
        this.isLoader = false
        this.isResult = true
        this.jobs = response.data
      })
    }

    protected searchBargeOperations () {
      this.activities = []
      this.isResult = false
      this.isLoader = true
      this.isBargeOperation = false
      SearchService.searchBargeOperations(this.bargeOperation).then((response) => {
        this.isLoader = false
        this.isResult = true
        this.isBargeOperation = true
        this.activities = response.data.map(item => ({
          date: item.created_date,
          job_number: item.reference_number,
          type_of_cargo: [...new Set(item.activities.map(activity => activity.service_type))],
          quantity_collected: item.activities.reduce(function (res, value) {
            const result : any = []
            if (!res[value.service_type]) {
              res[value.service_type] = { service_type: value.service_type, quantity: 0 }
              result.push(res[value.service_type])
            }
            res[value.service_type].quantity += parseInt(value.quantity)
            return res
          }, {}),
          quantity_delivered: item.dispatch_note_jobs.reduce(function (res, value) {
            const result : any = []
            if (!res[value.service_type]) {
              res[value.service_type] = { service_type: value.service_type, tonnage: 0 }
              result.push(res[value.service_type])
            }
            res[value.service_type].tonnage += parseInt(value.tonnage)
            return res
          }, {})
        }))
        console.log(this.activities)
      })
    }

    private populateServices () {
      this.services = []
      this.selectedServices = []
      RepositoryService.getServices().then((response) => {
        response.data.forEach((element: {
          id: any; name: any
        }) => {
          this.services.push({
            value: {
              id: element.id,
              name: element.name
            },
            text: element.name
          })
        })
        this.selectedServices = this.services.filter(service => service.text === 'Sludge' || service.text === 'Garbage' || service.text === 'Other')
      })
    }

    private populateBarges () {
      this.barges = []
      CollectionService.getBargeNames().then((response) => {
        response.data.forEach((barge) => {
          this.barges.push({
            value: {
              id: barge.id,
              name: barge.name
            },
            text: barge.name
          })
        })
      })
    }

    protected download () {
      SearchService.getJobsSummary(this.date).then((response) => {
        this.jobDetails = response.data.map(item => ({
          'Job Number': item.job_number,
          'Reference Number': item.reference_number,
          Vessel: item.vessel_name,
          Port: item.port_name,
          Terminal: item.terminal_name,
          'Vessel Arrival Date': item.vessel_arrival_date,
          'Voyage Number': item.voyage_number,
          Category: item.category,
          'Transaction Type': item.transaction_type,
          'Contact Person': item.contact_person,
          'Contact Person Number': item.contact_person_number,
          'Officer-in-Charge': item.officer_in_charge_name,
          Barges: item.barges.map(function (e) { return e.barge_name }).join(', '),
          Services: item.inventories.map(function (e) { return e.service_type + ' - ' + e.quantity + ' ' + e.unit_of_measurement }).join(', '),
          Participants: item.job_participants.map(function (e) { return e.name }).join(', '),
          'Dispatch (Bowser - Tonnage)': item.dispatch_note_jobs.map(function (e) { return e.vehicle_number + ' - ' + e.total_tonnage }).join(', '),
          Status: item.status
        }))

        const ws = utils.json_to_sheet(this.jobDetails)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'Data')
        writeFile(wb, 'Summary_' + DateHelper.today('yyyymmdd') + '.xlsx')
      })
    }

    protected downloadJobSummary () {
      SearchService.getJobSummary(this.searchKey).then((response) => {
        this.jobDetails = response.data.map(item => ({
          'Job Number': item.job_number,
          'Reference Number': item.reference_number,
          'Vessel Name': item.vessel_name,
          'Vessel Arrival Date': item.vessel_arrival_date,
          'Voyage Number': item.voyage_number,
          Category: item.category,
          Port: item.port_name,
          Terminal: item.terminal_name,
          'Transaction Type': item.transaction_type,
          'Contact Person': item.contact_person,
          'Contact Person Number': item.contact_person_number,
          'Officer-in-Charge': item.officer_in_charge_name,
          Barges: item.barges.map(function (e) { return e.barge_name }).join(', '),
          Services: item.inventories.map(function (e) { return e.service_type + ' - ' + e.quantity + ' ' + e.unit_of_measurement }).join(', '),
          Participants: item.job_participants.map(function (e) { return e.name }).join(', '),
          'Dispatch (Bowser - Tonnage)': item.dispatch_note_jobs.map(function (e) { return e.vehicle_number + ' - ' + e.total_tonnage }).join(', '),
          Status: item.status
        }))

        // const ws = utils.json_to_sheet(Object.entries(this.jobSummary))
        const ws = utils.json_to_sheet(this.jobDetails)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'Data')
        writeFile(wb, 'Summary_' + DateHelper.today('yyyymmdd') + '.xlsx')
      })
    }

    protected downloadReport () {
      SearchService.getJobsReport(this.selectedService.id).then((response) => {
        this.jobDetails = response.data.map(item => ({
          'Job Number': item.reference_number,
          'Date of Job done': item.completed_date,
          'Name of Vessel': item.vessel_name,
          'Quantity Collected': item.inventories.map(function (e) { return e.quantity }).join(', '),
          'Oil Quantity': item.inventories.map(function (e) { return e.oil_quantity }).join(', '),
          'Water Quantity': item.inventories.map(function (e) { return e.water_quantity }).join(', '),
          'Owners Charges': item.refund !== null ? item.refund.lines.filter(line => line.type === 'Staff').reduce((sum, line) => { return sum + parseInt(line.amount) }, 0) : 0,
          'Agent Commission': item.refund !== null ? item.refund.lines.filter(line => line.type === 'Agent').reduce((sum, line) => { return sum + parseInt(line.amount) }, 0) : 0,
          'Other Payment': item.refund !== null ? item.refund.lines.filter(line => line.type === 'Other').reduce((sum, line) => { return sum + parseInt(line.amount) }, 0) : 0,
          'Operational Cost': 0,
          'Total Cost': item.refund !== null ? item.refund.lines.reduce((sum, line) => { return sum + parseInt(line.amount) }, 0) : 0,
          'Charges Collect': item.invoice !== null ? item.invoice.gross_amount : 0
        }))

        const ws = utils.json_to_sheet(this.jobDetails)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'Data')
        writeFile(wb, 'Report_' + DateHelper.today('yyyymmdd') + '.xlsx')
      })
    }

    public cancel () {
      this.reset(this.date)
    }

    public cancelSelectedService () {
      this.selectedService = {}
    }

    public cancelBargeOperation () {
      this.reset(this.bargeOperation)
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

